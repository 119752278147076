import  Venue  from 'src/smoothr-web-app-core/models/Venue';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {SelectTableModalComponent} from 'src/app/components/select-table-modal/select-table-modal.component';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss']
})
export class OrderPage extends RepositoryDirective implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';
	loading = false;
	order: Order;
	venue: Venue;
	constructor(
		protected repository: RepositoryService,
		public translate: TranslateService,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController,
	) {
		super(repository);
	}

	static navigate(router: Router) {
		return router.navigateByUrl(AppComponent.largeScreen ? MenuPage.url : OrderPage.url);
	}

	onVenue() {
		super.onVenue();
	}

	onOrder() {
		super.onOrder();
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log('ngOnInit', this.order, this.venue);
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}
	}

	backClick() {
		MenuPage.navigate(this.router);
	}
	async openSelectTableModal() {
		if (this.venue && this.order.preorder.type === PreorderType.INSIDE) {
			const selectTable = await SelectTableModalComponent.show(this.modalCtrl, this.venue, this.order);
			if (selectTable) {
				this.order.tableNumber = selectTable.table;
				this.order.table = selectTable.tableId;
				this.repository.order.emit(this.order);
			}
		}
	}
}
