import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const iamloveCustomerGroup = 'iamlove';
export const customerGroup = iamloveCustomerGroup;

const supportEmail = {
	iamlove: 'iamlove@smoothr.de'
};
const firebaseConfig = {
	iamlove: {
		apiKey: "AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw",
		authDomain: "prod-env-smoothr.firebaseapp.com",
		databaseURL: "https://prod-env-smoothr.firebaseio.com",
		projectId: "prod-env-smoothr",
		storageBucket: "prod-env-smoothr.appspot.com",
		messagingSenderId: "640058019537",
		appId: "1:640058019537:web:5f2178ec808a76bfcbfff0",
		measurementId: "G-7PMP97LZHM"
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAG9gPZj2nYEENGlwy7x5zUfHfazFLMx0o',
	baseUrl: 'https://api.smoothr.de',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	countryList: ['de'],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment
};
