import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { OrderSuccessRoutingModule } from './order-success-routing.module';
import { OrderSuccessComponent } from './order-success.component';
import { SharedModuleModule } from 'src/app/shared-module/shared-module.module';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    OrderSuccessComponent
  ],
  imports: [
    CommonModule,
    OrderSuccessRoutingModule,
    SharedModuleModule,
    IonicModule,
    FormsModule,
    
  ]
})
export class OrderSuccessModule { }
