import {TranslateService} from '@ngx-translate/core';
import {Component, OnInit} from '@angular/core';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {ActivatedRoute, Router} from '@angular/router';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {environment} from '../../../environments/environment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {sleep, venueAcceptsOrders} from '../../../smoothr-web-app-core/utils/utils';
import {MenuPage} from '../menu/menu.page';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import Address from '../../../smoothr-web-app-core/models/Address';
import { Api } from 'src/smoothr-web-app-core/api/api';
import { MatSnackBar } from '@angular/material/snack-bar';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-home',
	templateUrl: './home.page.iamlove.html',
	styleUrls: ['./home.page.iamlove.scss']
})
export class HomePage extends RepositoryDirective implements OnInit {
	static url = 'home';
	static scanUrl = 'scan-qr/:venueId/:tableId';
	environment = environment;
	languageEnum = Language;

	loading = false;
	preorderTypes = [PreorderType.INSIDE, PreorderType.TAKE_AWAY];
	active: any = {};

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		public translate: TranslateService,
		private route: ActivatedRoute,
		private snackBar: MatSnackBar,
		) {
		super(repository);
		
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		await router.navigateByUrl(HomePage.url);
		if (repository) {
			repository.resetVenue();
			repository.address.emit(null);
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
	}

	ngOnInit() {
		super.ngOnInit();
		this.loading = true;
		this.loadVenues();
		this.route.paramMap.subscribe((params) => {			
			if (params && params.get('tableId') && params.get('venueId')) {
				this.loadVenueByQrCode(params.get('tableId'), params.get('venueId'));
			}
		});
	}


	ionViewDidEnter() {
		this.repository.order.emit(null);
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores(preorderType: PreorderType) {
		this.loading = true;
		const venue = (await this.repository.getAllVenues()).find(ven => venueAcceptsOrders(ven, preorderType));
		if (!venue) {
			this.loading = false;
			return;
		}
		await this.loadVenueAndCreateOrder(venue, null, preorderType);
	}

	async loadVenueAndCreateOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		const loadedVenue = await this.repository.getVenue(venue._id);
		this.repository.createOrder(loadedVenue, address, preorderType, null);
		await MenuPage.navigate(this.router);
		this.loading = false;
	}

	loadVenues() {
		for (const preorderType of this.preorderTypes) {
			this.active[preorderType] = false;
		}
		new Promise<void>(async (resolve, reject) => {
			try {
				const venues = await this.repository.getAllVenues();
				for (const venue of venues) {
					for (const preorderType of this.preorderTypes) {
						this.active[preorderType] = this.active[preorderType] || venueAcceptsOrders(venue, preorderType);
					}
				}
				resolve();
			} catch (e) {
				reject(e);
			}
		}).finally(() => (this.loading = false));
	}
	async goToMenu(type: PreorderType) {
		this.locateAndShowStores(type);
	}
	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}

	async loadVenueByQrCode(tableId: string, venueId: string) {
		this.loading = true;
		try {
			const venueResponse = await this.repository.getVenue(venueId);
			const table = (await Api.getTable(venueResponse._id, tableId)).data;
			if (venueResponse && table) {
				this.repository.createOrder(this.venue, null, PreorderType.INSIDE, null);
				this.order.tableNumber = table.number;
				this.order.table = table._id;
				this.repository.order.emit(this.order);
				sleep(200);
			  MenuPage.navigate(this.router);
			}
			this.loading = false;
		}
		catch (e) {
			this.loading = false;
			this.snackBar.open(this.translate.instant('home_page.snack_table_error'), null, {
				duration: 2000,
			});
		}
	}
}
