import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Api } from 'src/smoothr-web-app-core/api/api';
import RepositoryDirective from 'src/smoothr-web-app-core/directives/repository-directive';
import Order from 'src/smoothr-web-app-core/models/Order';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import { RepositoryService } from 'src/smoothr-web-app-core/services/repository/repository.service';
import { OrderUtils } from 'src/smoothr-web-app-core/utils/order-utils';
import { numberToCurrency } from 'src/smoothr-web-app-core/utils/utils';
import { HomePage } from '../home/home.page';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent extends RepositoryDirective implements OnInit {
  static urlWithOrderParams = 'order/:orderId';
  loading = false;
  orderId = '';
  orderVenue: Venue;
  error: any;
  environment = environment;
  numberToCurrency = numberToCurrency;
  OrderUtils = OrderUtils;
  verifiedOrder: Order; 
  constructor(
    private route: ActivatedRoute,
    protected repository: RepositoryService,
    private translate: TranslateService,
    private router: Router,
    private snackbarCtrl: MatSnackBar,
  ) { 
    super(repository)
  }
  async onInitFinish() {
    super.onInitFinish();
    console.log(this.verifiedOrder);
  }

  static async navigate(router: Router, orderId: string) {
    await router.navigate(['order/' + orderId], {
     queryParams: {
      orderId
     }
    });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      console.log({params});
      this.orderId = params?.orderId;
      this.reloadOrder();
    })
  }

  ionViewDidEnter() {
    this.repository.order.emit(null);
  }

  home() {
    if (this.loading) {
      return;
    }
    if (this.error) {
      this.reloadOrder();
      return;
    }

    this.loading = true;
    HomePage.navigate(this.router, this.repository);
  }

  async reloadOrder(attemp: number = 0, error = null ) {
    this.loading = true;
    this.error = false;

    if (attemp > 5) {
      this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
      this.snackbarCtrl.open(error, null, {
        duration: 2000
      });
      this.loading = false;
      return;
    }

    try {
      const response = (await Api.getOrder(this.orderId)).data;
      this.verifiedOrder = response;
      if (response.venue) {
        this.orderVenue = (await Api.getLazyVenue(response.venue)).data
      }
      this.loading = false;
    }
    catch(e) {
      await this.reloadOrder(attemp + 1, e)
      console.log(e);
      this.loading = false;
    }
  }
}

